import * as React from "react"
import Layout from '../components/layout';
import { graphql, useStaticQuery } from "gatsby";
import SubCard from "../components/sub-card";
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../components/seo";


const RankingPage = () => {
  const data = useStaticQuery(graphql`
    query{
      allMdx {
        edges {
          node {
            frontmatter {
              date
              title
              slug
              tag
              thumbnail {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            body
          }
        }
      }
    }
  `);

  const top = data.allMdx.edges.find(edge => {
    return edge.node.frontmatter.slug === "ranking"
  })

  const others = data.allMdx.edges.filter(edge => {
    return edge.node.frontmatter.slug !== "ranking" && edge.node.frontmatter.tag === "ランキング"
  })

  return (
    <Layout>
      <SEO title="無濾過生原酒ブログ　ランキング" description="勝手にいろんなランキングをつけてみます。"/>
      <div>
        <div className="my-4 p-4 lg:mx-4">
          <div className="p-4 bg-white rounded-lg markdown">
            <MDXRenderer>{top.node.body}</MDXRenderer>
          </div>
        </div>
        <div className="lg:flex lg:flex-wrap">
          {
            others.map(other => {
              console.log(other.node);
              return <SubCard 
                title={other.node.frontmatter.title} 
                src={other.node.frontmatter.thumbnail.childImageSharp.fluid.src}
                tag={other.node.frontmatter.tag} 
                slug={other.node.frontmatter.slug}/>
            })
          }
        </div>
      </div>
    </Layout>
  )
}

export default RankingPage
