import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from "@reach/router"

const SEO = ({ title, description, logoImage }) => {
    const { site } = useStaticQuery(query);
    const { defaultTitle, defaultDescription } = site.siteMetadata;
    const { pathname } = useLocation()

    const seo = {
        title: title ? `${title} ${defaultTitle}` : defaultTitle,
        description: description || defaultDescription,
        logo: logoImage ? 'https://muroka-namagensyu.com' + logoImage : ''
    }
    return (
        <Helmet>
            <html lang="ja" />
            <title>{seo.title}</title>
            <meta property="og:url" content={'https://muroka-namagensyu.com' + pathname}></meta>
            <meta property="og:title" content={seo.title}></meta>
            <meta name="description" content={seo.description} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.logo}></meta>
            <meta name="twitter:card" content="summary_large_image"></meta>
        </Helmet>
    )
}

export default SEO;

const query = graphql`
    query {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
            }
        }
    }
`;
