import React from 'react'

function TagBtn(props) {
    return (
        <div className="w-36 h-6 text-sm flex justify-center items-center shadow rounded-full bg-green-200 hover:bg-green-400 hover:text-white transition-colors">
            {props.tag}
        </div>
    )
}

export default TagBtn
