import React from 'react';
import TagBtn from './tag-button';
import { Link } from 'gatsby';

function SubCard(props) {
    return (
        <div className="bg-white m-4 p-4 lg:mx-8 lg:w-2/5 rounded-lg">
            <div className="flex justify-between">
                <div className="pb-4">
                    <TagBtn tag={props.tag} />
                </div>
                <div className="text-sm">{props.date}</div>
            </div>

            <h2 className="text-lg">
                <Link to={`/blog/${props.slug}`} >{props.title}</Link>
            </h2>
            <div className="text-sm">
                <Link to={`/blog/${props.slug}`} >
                    <img src={props.src} />
                </Link>
            </div>
        </div>
    )
}

export default SubCard
